export const learnboostStorage = {
    templateData: {
        pdf_viewer: {
            current_page: 0,
        },
        summary: {},
        flashcards: {},
        tutor: {},
    },

    _getDataForDocument: (document_id) => {
        const data = JSON.parse(localStorage.getItem(document_id) || "{}");
        return { ...learnboostStorage.templateData, ...data };
    },

    _setDataForDocument: (document_id, data) => {
        localStorage.setItem(document_id, JSON.stringify(data));
    },

    getCurrentPagePdfViewer: (document_id) => {
        return learnboostStorage._getDataForDocument(document_id).pdf_viewer.current_page || 0;
    },

    setCurrentPagePdfViewer: (document_id, current_page) => {
        const data = learnboostStorage._getDataForDocument(document_id);
        data.pdf_viewer.current_page = current_page;
        learnboostStorage._setDataForDocument(document_id, data);
    },
};
