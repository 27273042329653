import React, { useCallback, useEffect, useMemo, useState } from "react";

import { createStore, Viewer, Worker } from "@react-pdf-viewer/core";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";

import { learnboostStorage } from "../../utils/learnboostStorage";
import styles from "./reactpdfviewer.module.css";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import PdfIcon from "../PdfIcon";

const CustomZoomPlugin = () => {
    const store = useMemo(() => createStore({}), []);

    return {
        install: (pluginFunctions) => {
            store.update("zoom", pluginFunctions.zoom);
        },
        zoomTo: (scale) => {
            const zoom = store.get("zoom");
            if (zoom) {
                // Zoom to that scale
                zoom(scale);
            }
        },
    };
};

const JumpToPagePlugin = () => {
    const store = React.useMemo(() => createStore({}), []);

    return {
        install: (pluginFunctions) => {
            store.update("jumpToPage", pluginFunctions.jumpToPage);
        },
        jumpToPage: useCallback(
            (pageIndex) => {
                const fn = store.get("jumpToPage");
                if (fn) {
                    fn(pageIndex);
                }
            },
            [store]
        ),
    };
};

const ReactPDFViewer = ({
    pdfUrl,
    highlightAreas,
    targetPageReference,
    initialPage,
    document_id,
}) => {
    const [numPages, setNumPages] = useState(0);
    const [scale, setScale] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [targetPage, setTargetPage] = useState("1");
    const customZoomPluginInstance = CustomZoomPlugin();
    const { zoomTo } = customZoomPluginInstance;
    const [areas, setAreas] = useState([]);

    const jumpToPagePluginInstance = JumpToPagePlugin();
    const { jumpToPage } = jumpToPagePluginInstance;

    useEffect(() => {
        jumpToPage(parseInt(targetPageReference) - 1);
    }, [targetPageReference, jumpToPage]);

    useEffect(() => {
        setAreas(highlightAreas);
    }, [highlightAreas]);

    const handleDocumentLoadSuccess = (props) => {
        setNumPages(props.doc._pdfInfo.numPages); // Update state with the total number of pages
    };

    const fullScreenPluginInstance = fullScreenPlugin({
        renderExitFullScreenButton: (props) => (
            <div className={styles.exitbutton} onClick={props.onClick}>
                Exit
            </div>
        ),
    });

    const { EnterFullScreen } = fullScreenPluginInstance;

    const pageNavigationPluginInstance = pageNavigationPlugin();

    const renderHighlights = (props) => {
        return (
            <div>
                {areas
                    .filter((area) => area.pageIndex === props.pageIndex)
                    .map((area, idx) => (
                        <div
                            key={idx}
                            className="highlight-area"
                            style={Object.assign(
                                {},
                                {
                                    background: "yellow",
                                    opacity: 0.2,
                                },
                                props.getCssProperties(area, props.rotation)
                            )}
                        />
                    ))}
            </div>
        );
    };

    const highlightPluginInstance = highlightPlugin({
        renderHighlights,
        trigger: Trigger.None,
    });

    useEffect(() => {
        setTargetPage(String(currentPage));
    }, [currentPage]);

    const handlePageChange = (e) => {
        setCurrentPage(e.currentPage + 1);
        learnboostStorage.setCurrentPagePdfViewer(document_id, e.currentPage);
    };

    const handlePageJump = (targetPage) => {
        const pageNumber = parseInt(targetPage - 1, 10);
        jumpToPage(pageNumber);
        learnboostStorage.setCurrentPagePdfViewer(document_id, pageNumber);
    };

    const handleZoomIn = (prevScale) => {
        const newScale = Math.min(2.5, prevScale + 0.1);
        setScale(newScale);
        return newScale;
    };

    const handleZoomOut = (prevScale) => {
        const newScale = Math.max(0.3, prevScale - 0.1);
        setScale(newScale);
        return newScale;
    };

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.pageNavContainer}>
                    <input
                        className={styles.form}
                        type="text"
                        value={targetPage}
                        onChange={(e) => setTargetPage(e.target.value)}
                        onBlur={() => {
                            handlePageJump(targetPage);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handlePageJump(targetPage);
                            }
                        }}
                    />
                    <span className={styles.pageNumber}>{numPages}</span>
                    <div className={styles["group2"]}>
                        <button
                            className={styles.zoomIn}
                            onClick={() => zoomTo(handleZoomIn(scale))}>
                            <PdfIcon name="zoom-in" />
                        </button>
                        <button
                            className={styles.zoomOut}
                            onClick={() => zoomTo(handleZoomOut(scale))}>
                            <PdfIcon name="zoom-out" />
                        </button>
                        <EnterFullScreen>
                            {(props) => (
                                <button className={styles.fullscreen} onClick={props.onClick}>
                                    <PdfIcon name="fullscreen" />
                                </button>
                            )}
                        </EnterFullScreen>
                    </div>
                </div>
                <div className={styles.pageViewContainer}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div className={styles.viewer} onClick={() => setAreas([])}>
                            <Viewer
                                fileUrl={pdfUrl}
                                initialPage={initialPage}
                                onDocumentLoad={handleDocumentLoadSuccess}
                                onPageChange={handlePageChange}
                                plugins={[
                                    customZoomPluginInstance,
                                    fullScreenPluginInstance,
                                    jumpToPagePluginInstance,
                                    pageNavigationPluginInstance,
                                    highlightPluginInstance,
                                ]}
                            />
                        </div>
                    </Worker>
                </div>
            </div>
        </>
    );
};

export default ReactPDFViewer;
